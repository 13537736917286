import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// material-ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

// third party
import * as Yup from "yup";
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
// // import AddStoryComment from './AddStoryComment';
// import StoryComment from './StoryComment';
import AlertPackageDelete from "./AlertPackageDelete";
import { openSnackbar } from "store/snackbar/slice";
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from "store";
import { updatePackage } from "store/catalogue/slice";
// assets
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import SelectItem from "./SelectItem";
import AmountCard from "./AmountCard";
import { gridSpacing } from "utils/constantString";
import ItemList from "./ItemList";
import { CONST_categoryType } from "utils/primtive/staticData";
import EditTitle from "./EditTitle";
import { DialogTitle, FormControlLabel, InputAdornment, Switch } from "@mui/material";
import { useCompanyData } from "components/hooks/useCompanyData";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

const validationSchema = Yup.object({
  packageName: Yup.string().required("Package Name is required"),
  // dueDate: Yup.string().required("Due date is required"),
  validity:Yup.string().required("Validity is required"),
});

// ==============================|| KANBAN BACKLOGS - EDIT STORY ||============================== //

const EditPackage = ({ open, handleDrawerOpen, onUpdatePackage, editableData })  => {
  const dispatch = useDispatch();
  const companyId = useCompanyData();
  const packageData = useSelector((state) => state.catalogue.serviceList);
  const serviceList = useSelector(
    (state) => state.catalogue.serviceListExcludingCatalogue
  );
  const [productsData, setProductsData] = useState([]);
  const [productsDisplayData, setProductsDisplayData] = useState([]);
  const [addItemClicked, setAddItemClicked] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [allAmounts, setAllAmounts] = useState({
    subTotal: 0,
    appliedTaxValue: 0,
    appliedDiscountValue: 0,
    taxesAmount: 0,
    discountAmount: 0,
    totalAmount: 0,
  });
  const [validity, setValidity] = useState({
    duration: "",
    unit: "days",
  });
  useEffect(() => {
    if(editableData){
      const data = editableData?.packageItems?.map((i)=>{
        return (
          {
            id: i?.serviceId,
            title: i.title,
            description: i?.note,
            quantity: i?.quantity,
            amount: i.price,
            // discount: `${i.selectedDiscount} ${i.selectedDiscountMode == 'PERCENTAGE' ? '%' : 'INR'}`,
            discount: '',
            total: i.totalPrice,
          }
        )
      })
      setProductsDisplayData(data)

      const productTemp = editableData?.packageItems?.map((i)=>{
        return (
          {
              itemId: i?.serviceId,
              description: i?.note,
              price: i?.price,
              duration:i?.duration,
              itemType:i?.itemType,
              quantity: i?.quantity,
              totalPrice: i?.totalPrice,
          }
        )
      })
      setProductsData(productTemp);

      let amounts = {
        subTotal: parseFloat(editableData?.grossPrice),
        appliedTaxValue: 0,
        appliedDiscountValue: 0,
        taxesAmount: 0,
        discountAmount: parseFloat(editableData?.netPrice),
        totalAmount: parseFloat(editableData?.netPrice),
      };

      amounts.taxesAmount = parseFloat(amounts.subTotal) * amounts.appliedTaxValue;
      amounts.discountAmount = (parseFloat(amounts.subTotal) + amounts.taxesAmount) * amounts.appliedDiscountValue;
      amounts.totalAmount = parseFloat(amounts.subTotal) + amounts.taxesAmount - amounts.discountAmount;

      setAllAmounts(amounts);
    }
  }, [])
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
        packageName: editableData?.title ?? '',
        dueDate:editableData?.dueDate ?? '',
        validity:editableData?.dueDay ?? '',
        validityMode:'days',
        discount:editableData?.discountValue ?? 0,
        discountedMode:editableData?.discountType ?? 'percentage',
        discountedValue:editableData?.netPrice ?? '',
        totalDuration:editableData?.grossPrice ?? '',
        notes:editableData?.description ?? '',
        enableForAppointment:editableData?.status ? editableData?.status === "active" ? true : false : true,
    },
    validationSchema,
    onSubmit: (values) => {
      if(values){
        const temp =  values?.validityMode === 'days' ? values?.validity : values?.validityMode === 'months' ? values?.validity * 30 : values?.validity * 360
        const body = {
          ...(editableData && {id: editableData?.id}),
          // catalogueId: 92444d8b-9d13-401c-a56b-f3943c549d65,
          companyId:companyId,
          title: values?.packageName,
          description: values.notes,
          dueDay: values?.validityMode === 'days' ? values?.validity : values?.validityMode === 'months' ? values?.validity * 30 : values?.validity * 360,
          // validity : values?.validityMode === 'days' ? values?.validity : values?.validityMode === 'months' ? values?.validity * 30 : values?.validity * 360
          grossPrice: allAmounts.totalAmount,
          netPrice: parseFloat(values?.discountedValue) ?? allAmounts.totalAmount,
          totalDuration: values?.totalDuration,
          ...( values?.dueDate && {dueDate: values?.dueDate}),
          status: values?.enableForAppointment ? 'active' :'inactive',
          packageItems: productsData,
          discountValue:values.discount,
          discountType :values?.discountedMode
      }
      onUpdatePackage(body)
    }
       
    }
    });

    const {
      handleSubmit,
      handleChange,
      values,
      handleBlur,
      errors,
      touched,
      setFieldValue,
      isSubmitting,
      resetForm
    } = formik;

    const handleModalClose = (status) => {
        setOpenModal(false);
        if (status) {
            handleDrawerOpen();
            //dispatch(deletePackage(story.id, userStory, userStoryOrder));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Story Deleted successfully',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        }
    };
    // to delete row in order details
    const deleteProductHandler = (row) => {
      const updatedProductData = productsData.filter((item) => item.itemId !== row?.id)
        setProductsData(updatedProductData);
        setProductsDisplayData(productsDisplayData.filter((item) => item.id !== row?.id));
        const amounts = {
            subTotal: allAmounts.totalAmount - row?.total,
            appliedTaxValue: 0,    //10 % tax
            appliedDiscountValue: 0,  ////5% discount
            taxesAmount: 0,
            discountAmount: allAmounts?.selectedDiscount,
            totalAmount: allAmounts?.totalAmount
        };

        amounts.discountAmount = (amounts.subTotal) * amounts.appliedDiscountValue;
        amounts.taxesAmount = (amounts.subTotal - amounts.discountAmount) * amounts.appliedTaxValue;
        amounts.totalAmount = amounts.subTotal + amounts.taxesAmount - amounts.discountAmount;
        setAllAmounts(amounts);
    };

    // add item handler
    const handleAddItem = (addingData) => {
      setProductsDisplayData([
          ...productsDisplayData,
          {
              id: addingData.id,
              title: addingData.title,
              description: addingData.description,
              quantity: addingData.selectedQuantity,
              amount: addingData.price,
              discount: `${addingData.selectedDiscount} ${addingData.selectedDiscountMode == 'PERCENTAGE' ? '%' : 'INR'}`,
              total: addingData.totalAmount,
          }
      ])
        setProductsData([
            ...productsData,
            {
                itemId: addingData.id,
                description: addingData.description,
                price: addingData.price,
                duration:addingData.duration,
                itemType:addingData.selectedCategoryType,
                quantity: addingData.selectedQuantity,
                totalPrice: addingData.totalAmount,
            }
        ]);

      let tempDuration = addingData.duration;
      const amounts = {
          subTotal: addingData.totalAmount,
          appliedTaxValue: 0,    //10 % tax
          appliedDiscountValue: 0,  ////5% discount
          taxesAmount: 0,
          discountAmount: addingData?.selectedDiscount,
          totalAmount: addingData?.totalAmount
      };
      productsData.forEach((item) => {
          amounts.subTotal += parseFloat(item.price);
          tempDuration += item.duration;
      });

      amounts.discountAmount = (amounts.subTotal) * amounts.appliedDiscountValue;
      amounts.taxesAmount = (amounts.subTotal - amounts.discountAmount) * amounts.appliedTaxValue;
      amounts.totalAmount = amounts.subTotal + amounts.taxesAmount - amounts.discountAmount;
      setAllAmounts(amounts);

      setAddItemClicked(false);

      // console.log("subtotal",tempDuration );
      setFieldValue('totalDuration', tempDuration)
  };
    
 
    // const handleDueDate = (value, unit) => {
    //     if(value){
    //         if(unit === 'days'){
    //             setFieldValue('dueDate', dayjs().add(value, 'day')) 
    //         }else if(unit === 'months'){
    //             setFieldValue('dueDate', dayjs().add(value * 30, 'day')) 
    //         }else{
    //             setFieldValue('dueDate', dayjs().add(value * 365, 'day')) 
    //         }
    //     }
    // } 
    // const handleDurationChange = (event) => {
    //     handleDueDate(event.target.value, validity.unit);
    //     setFieldValue('validity', event.target.value);
    //     setValidity((prevValidity) => ({
    //       ...prevValidity,
    //       duration: event.target.value,
    //     }));
    //   };
    
    //   const handleUnitChange = (event) => {
    //     handleDueDate(validity.duration, event.target.value);
    //     setFieldValue('validity', validity.duration);
    //     setValidity((prevValidity) => ({
    //       ...prevValidity,
    //       unit: event.target.value,
    //     }));
    //   };

  return (
      <Drawer
        sx={{
          ml: open ? 3 : 0,
          flexShrink: 0,
          zIndex: 1200,
          overflowX: "hidden",
          width: { xs: 600, md: 1000 },
          "& .MuiDrawer-paper": {
            height: "100vh",
            width: { xs: 600, md: 1000 },
            position: "fixed",
            border: "none",
            borderRadius: "0px",
          },
        }}
        variant="temporary"
        anchor="right"
        open={open}
        ModalProps={{ keepMounted: true }}
        onClose={() => {
          handleDrawerOpen();
          resetForm();
        }}
      >
        <form onSubmit={handleSubmit}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item sx={{ width: "calc(100% - 50px)" }}>
                  <DialogTitle>
                    {editableData
                      ? <FormattedMessage id="service.section.title.update_package" defaultMessage={`Update Packages`} />
                      : <FormattedMessage id="service.section.title.add_package" defaultMessage={`Add Packages`} />
                    }
                  </DialogTitle>
                    {/* <Button
                      variant="text"
                      color="error"
                      sx={{ p: 0.5, minWidth: 32, display: { xs: "block" } }}
                      onClick={handleDrawerOpen}
                    >
                      <CloseIcon />
                    </Button> */}
                    {/* <EditTitle packageData={{title:"test"}} /> */}
                </Grid>
                {/* <Grid item sx={{ width: "calc(100% - 50px)" }}>
                  <Stack direction="row" alignItems="center">
                    <Button
                      variant="text"
                      color="error"
                      sx={{ p: 0.5, minWidth: 32, display: { xs: "block" } }}
                      onClick={handleDrawerOpen}
                    >
                      <CloseIcon />
                    </Button>
                    <EditTitle packageData={{title:"test"}} />
                  </Stack>
                </Grid> */}

                {/* <Grid item>
                  <Button
                    variant="text"
                    color="error"
                    sx={{ minWidth: 32 }}
                    onClick={() => setOpenModal(true)}
                  >
                    <DeleteTwoToneIcon />
                  </Button>
                  {openModal && (
                    <AlertPackageDelete
                      title={packageData.title}
                      open={openModal}
                      handleClose={handleModalClose}
                    />
                  )}
                </Grid> */}
              </Grid>
            </Box>
            <Divider />
            <PerfectScrollbar>
              <Box sx={{ p: 3 }}>
                <Grid container spacing={gridSpacing}>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Package Name"
                    name="packageName"
                    onChange={handleChange}
                    error={Boolean(touched.packageName && errors.packageName)}
                    // helperText={touched.packageName && errors.packageName}
                    value={values?.packageName}
                  />
                </Grid>
                
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Notes"
                    name="notes"
                    onChange={handleChange}
                    value={values?.notes}
                  />
                </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Validity"
                      name="validity"
                      value={values?.validity}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <FormControl>
                              <Select
                                labelId="unit-label"
                                value={values?.validityMode}
                                onChange={handleChange}
                                sx={{
                                  border: "none",
                                  ".MuiOutlinedInput-notchedOutline": {
                                    border: "none", // Removes the border from the Select box
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    border: "none", // Removes the border on hover
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      border: "none", // Removes the border when focused
                                    },
                                }}
                              >
                                <MenuItem value="days">Days</MenuItem>
                                {/* <MenuItem value="months">Months</MenuItem>
                                <MenuItem value="years">Years</MenuItem> */}
                              </Select>
                            </FormControl>
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(touched.validity && errors.validity)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                      <DesktopDatePicker
                          name="dueDate"
                          value={new Date(values?.dueDate)}
                          format="dd/MM/yyyy"
                          label={<FormattedMessage id="expiry_date" defaultMessage="Sale End date" /> }
                          onChange={(newValue) => setFieldValue('dueDate', newValue)} 
                          slotProps={{ textField: { fullWidth: true, error: false } }}
                          error={Boolean(touched.dueDate && errors.dueDate)}
                      />
                  </Grid>
                 
                  
                  {addItemClicked ? (
                    <Grid item xs={12}>
                      <SelectItem {...{ handleAddItem, setAddItemClicked }} />
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Button
                        variant="text"
                        onClick={() => setAddItemClicked(true)}
                      >
                        + Add Item
                      </Button>
                    </Grid>
                  )}

               {productsDisplayData?.length > 0 && 
                <Grid item xs={12}>
                    <ItemList
                      {...{
                        productsData: productsDisplayData,
                        deleteProductHandler,
                      }}
                    />
                </Grid>}

                <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values?.enableForAppointment}
                          name="enableForAppointment"
                          onChange={handleChange}
                        />
                      }
                      label={
                        <FormattedMessage
                          id="enable-for-appointment"
                          defaultMessage="Package Active"
                        />
                      }
                    />
                  </Grid>

                {/* <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="outlined-multiline-flexible"
                        label="Notes"
                        name="notes"
                        onChange={handleChange}
                        multiline
                        value={values.notes}
                        rows={3}  
                    />
                  </Grid> */}

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <AmountCard {...{ allAmounts , formik}} />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button variant="contained" type="submit" disabled={isSubmitting}>
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </PerfectScrollbar>
          </LocalizationProvider>
        </form>
      </Drawer>
    )
};

EditPackage.propTypes = {
  open: PropTypes.bool,
  handleDrawerOpen: PropTypes.func,
  onUpdatePackage:PropTypes.func,
  editableData:PropTypes.object
};

export default EditPackage;
