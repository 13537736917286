import { Grid, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "modules/@mobiscroll/react/css/mobiscroll.min.css";
// material-ui
import Box from "@mui/material/Box";
import { Drawer, Typography, DialogTitle, Divider } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSelector } from "react-redux";
import InvoiceCatelogueItemList from "components/invoice/Create/InvoiceCatelogueItemList";
import InventoryHistoryTable from "./InventoryHistoryTable";
import InventoryBilledTable from "./InventoryBilledTable";
export const InventoryHistory = ({ open, onClose, clientDetail }) => {
  const [value, setValue] = useState("0");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const productCatalogues = 
    {
      id: "6180606a-8233-4363-b38b-85e459658114",
      title: "face pack",
      description: "multani miti natural face pack",
      price: "899.00",
      inventory: {
        quantity: 6,
        threshold: 1,
        lastBillDate: "2024-11-18",
        // comment
        inventoryTransactions: [
          {
            id: "9e4febbb-d5c5-4832-b18a-7dad5bc76bdf",
            transactionDate: "2024-11-06T03:54:37.937Z",
            quantity: 2,
            comment: "vdfv",
          },
          {
            id: "4ddfa754-f3f9-46cf-a796-910503d9813b",
            createdAt: "2024-11-06T01:44:03.367Z",
            updatedAt: "2024-11-06T01:44:03.367Z",
            createdBy: null,
            updatedBy: null,
            deletedAt: null,
            inventoryId: "b07fe9d8-9c99-4c42-9a6d-e3249e27a74a",
            transactionType: "optional",
            quantity: 2,
            transactionDate: "2024-11-06T01:44:03.367Z",
            comment: "sdfd",
          },
          {
            id: "d3e0f95f-053a-4e08-bfc2-bf91a5a97ef9",
            createdAt: "2024-11-06T01:45:11.280Z",
            updatedAt: "2024-11-06T01:45:11.280Z",
            createdBy: null,
            updatedBy: null,
            deletedAt: null,
            inventoryId: "b07fe9d8-9c99-4c42-9a6d-e3249e27a74a",
            transactionType: "optional",
            quantity: 2,
            transactionDate: "2024-11-06T01:45:11.280Z",
            comment: "dfdd",
          },
        ],
      },
    };
  return (
    <Drawer
      sx={{
        ml: open ? 3 : 0,
        flexShrink: 0,
        zIndex: 1200,
        overflowX: "hidden",
        width: { xs: 600, md: 1000 },
        "& .MuiDrawer-paper": {
          height: "100vh",
          width: { xs: 600, md: 1000 },
          position: "fixed",
          border: "none",
          borderRadius: "0px",
        },
      }}
      variant="temporary"
      anchor="right"
      open={open}
      ModalProps={{ keepMounted: true }}
      onClose={() => {
        onClose();
      }}
    >
      <Grid item sx={{ width: "calc(100% - 50px)" }}>
        <DialogTitle>Inventory History</DialogTitle>
      </Grid>
      <Divider />
      <Box sx={{ width: "100%", typography: "body1", padding: "25px" }}>
        <TabContext value={value}>
          <Box
            sx={{
              position: "relative",
              marginTop: "-74px",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                sx={{ textTransform: "uppercase", letterSpacing: "0.03rem" }}
                label="Inventory Topup"
                value="0"
              />
              <Tab
                sx={{ textTransform: "uppercase", letterSpacing: "0.03rem" }}
                label="Billed"
                value="1"
              />
            </TabList>
          </Box>
          <TabPanel value="0">
            <InventoryHistoryTable inventoryData={clientDetail?.inventory} />
          </TabPanel>
          <TabPanel value="1">
            <InventoryBilledTable orderDetails={clientDetail?.orderDetails} />
          </TabPanel>
        </TabContext>
      </Box>
    </Drawer>
  );
};

InventoryHistory.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  clientDetail: PropTypes.object,
};
