import React, { useState } from "react";
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import {
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addInventory } from "store/catalogue/slice";
import dayjs from "dayjs";
import Loader from "ui-component/Loader";

const EnventoryForm = ({ productData,handleClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      lastBillDate: productData?.inventory?.lastBillDate ?? [],
      quantity: productData?.inventory?.quantity ?? "",
      threshold: productData?.inventory?.threshold ?? "",
      comment: productData?.inventory?.comment ?? "",
    },
    onSubmit: (values) => {
      setLoading(true);
      dispatch(addInventory({...values, transactionType:'optional', productId:productData?.id})).then(() => {
        setLoading(false);
      });
    
    },
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    touched,
    getFieldProps,
  } = formik;

  return (
    <>
      {loading && <Loader />}
      <form onSubmit={handleSubmit}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid Xs={12} enventoryOpen={productData}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography
                sx={{
                  ml: 2,
                  mb: 2,
                  mt: 1,
                  fontSize: "20px",
                  fontWaight: "500",
                  color: "#121926",
                }}
              >
                Update Inventory Form
              </Typography>
              <Divider />
            </Grid>
          </Grid>
          <Grid xs={12} sx={{ p: 2 }}>
            <Grid container spacing={3} sx={{ px: 2 }}>

              <Grid item xs={12}>
                <DesktopDatePicker
                  label="Last Bill Date"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: false,
                    },
                  }}
                  format="dd/MM/yyyy "
                  value={new Date(values.lastBillDate)}
                  onChange={(newValue) => setFieldValue("lastBillDate", dayjs(newValue).format('YYYY-MM-DD'))}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Quantity"
                  name="quantity"
                  {...getFieldProps("quantity")}
                  error={Boolean(touched.quantity && errors.quantity)}
                  helperText={touched.quantity && errors.quantity}
                  value={values.quantity}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Threshold"
                  name="threshold"
                  {...getFieldProps("threshold")}
                  error={Boolean(touched.threshold && errors.threshold)}
                  helperText={touched.threshold && errors.threshold}
                  value={values.threshold}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Notes"
                  name="comment"
                  multiline
                  rows={2}
                  {...getFieldProps("comment")}
                  error={Boolean(touched.comment && errors.comment)}
                  helperText={touched.comment && errors.comment}
                  value={values.comment}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              spacing={2}
              justifyContent="flex-end"
              sx={{ px: 2, mt: 1, mb: 1 }}
            >
              <Grid item>
                <Button type="submit" variant="contained" onClik={handleSubmit}>
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button type="button" variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </form>
    </>

  );
};

export default EnventoryForm;
