import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { dispatch, useAppSelector as useSelector } from 'store';
//import { useAppDispatch as dispatch,useAppSelector as useSelector } from 'store';
// material-ui
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Autocomplete, styled, lighten, darken, Chip } from "@mui/material";
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// project imports

import { gridSpacing } from '../../../utils/constantString';
import InputLabel from '../../../ui-component/extended/Form/InputLabel';
import { categoryType, CONST_categoryType, PackageCategoryType } from 'utils/primtive/staticData';
import { fetchEmployeeServiceRelationData } from "store/staff/action";


// assets
import AddIcon from '@mui/icons-material/Add';
import { getStaffs } from 'store/employees/slice';
import { useCompanyData } from 'components/hooks/useCompanyData';
const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});
// ==============================|| CREATE INVOICE - SELECT ITEM ||============================== //

const SelectItem = ({ handleAddItem, setAddItemClicked }) => {
    const [selectedItem, setSelectedItem] = useState();
    const [selectedCategoryType, setSelectedCategoryType] = useState('');
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const [amount, setAmount] = useState(0);
    const [selectedDiscount, setSelectedDiscount] = useState(0);
    const [selectedDiscountMode, setSelectedDiscountMode] = useState('PERCENTAGE');
    const [rows, setRows] = useState([]);
    const [assignedStaffs, setAssignedStaffs] = useState([]);
    const [errors, setErrors] = useState({ quantityError: '' });
    const [isValidForm, setIsValidForm] = useState(false);
    const { products } = useSelector((state) => state.customer);
    const companyId = useCompanyData();

    const { serviceList, productList } = useSelector((state) => state.catalogue);
    const [isStaffSlectionDisabled , setIsStaffSlectionDisabled ] = useState(true);

    const staffs = useSelector((state) => state.staff.serviceStaffRelationList) || [];
    const employees = useSelector((state) => state.employees.employeeList) || [];
    //let isStaffSlectionDisabled = true
    //const staff = useSelector((state) => state.staff);

   
    // useEffect(() => {
    //     setRows(products);
    // }, [products]);
    useEffect(() => {
        setIsValidForm(true);
        if (selectedItem?.id) {
            //setAmount(selectedItem?.offerPrice * selectedQuantity);
            let price = selectedItem?.price * selectedQuantity;
            let discountedPrice = 0;
            if (selectedDiscount > 0) {
                
                discountedPrice = selectedDiscountMode=='PERCENTAGE' ? (price / 100)*selectedDiscount : selectedDiscount;
                if(discountedPrice > price){

                    setErrors({
                        ...errors,
                        discountError: 'Discount cannot be more than the actual price.'
                    });
                    setIsValidForm(false)
                    return;
                }else{
                    setErrors({
                        ...errors,
                        discountError: ''
                    });
                    setIsValidForm(true)
                }
                price = price - discountedPrice;
            }
            setAmount(price);
        }
    }, [selectedQuantity, selectedItem, selectedDiscount , selectedDiscountMode]);
    
    const handleAddInvoiceItem = (event) => {
        const value = event.target.value;
        setIsValidForm(true)
        if (event.target.name === "discount_mode") {
                setSelectedDiscountMode(value);
        }
        if (event.target.name === 'discount') {
            if (Number(value) < 0) {
                setErrors({
                    ...errors,
                    discountError: 'negative values not allowed'
                });
                setSelectedDiscount(value);
                setIsValidForm(false)

            } else {
                setSelectedDiscount(value);
            }

        }
        if (event.target.name === 'quantity') {
            if (Number(value) < 0) {
                setErrors({
                    ...errors,
                    quantityError: 'negative values not allowed'
                });
                setSelectedQuantity(value);
                setIsValidForm(false)
            } else if (Number(value) === 0) {
                setErrors({
                    ...errors,
                    quantityError: 'quantity can not be zero'
                });
                setSelectedQuantity(value);
                setIsValidForm(false)
            } else {
                setSelectedQuantity(value);
                setErrors({
                    ...errors,
                    quantityError: ''
                });
            }
        }
    };

    const handleOnAddItem = () => {
        const data = {
            ...selectedItem,
            totalAmount: amount,
            selectedQuantity,
            selectedCategoryType,
            selectedDiscount,
            selectedDiscountMode,
            assignedStaffs,
            selectedCategoryType
        };

        handleAddItem(data);
    };
    // to change the category list on basis of invoice and package page
    const path = window.location.pathname;

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} md={3}>
                <Stack spacing={1}>
                    <InputLabel sx={{ color: 'grey.500', fontWeight: '400' }}>Category Type</InputLabel>
                    <FormControl className='item-name-field'>
                        <Grid item>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={path==="/catalogue/packages/"?PackageCategoryType:categoryType}
                                onChange={(e, v, r) => {
                                    const value = v?.value;
                                    setSelectedCategoryType(value);
                                    let options = [];
                                    let staffList = [];
                                    let drivingList = ''   // either serviceList or productList
                                    let drivingKey = ''  // driving key to identify the list of service or list of products under the catlogue object
                                    if (value === 'service') {
                                        drivingList = serviceList;
                                        drivingKey = 'svcCtlgItems';

                                    } else {
                                        drivingList = productList;
                                        drivingKey = 'productCatalogues'

                                    }

                                    drivingList.map((option) => {
                                        const category = option?.title?.toUpperCase();
                                        option[drivingKey].map((o) => {
                                            options.push({ category: category, ...o });
                                            /*const filterStaffs = staff?.serviceStaffRelationList?.map((r)=>{
                                                if(r?.svcCtlgItemsId==o.id){
                                                    return {label:`${r?.employee?.firstName} ${r?.employee?.lastName}` , id:r.employeeId}
                                                }
                                            });*/
                                            //staffList = filterStaffs;

                                        });
                                    });
                                    // assignStaffs(staffList);
                                    setRows(options);
                                }}
                                name='category'
                                renderInput={(params) => <TextField {...params} placeholder="Please select" />}
                            />
                        </Grid>

                    </FormControl>
                </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
                <Stack spacing={1}>
                    <InputLabel sx={{ color: 'grey.500', fontWeight: '400' }} htmlFor="grouped-select">Item Name</InputLabel>
                    <FormControl className='item-name-field'>
                        <Grid item>
                            <Autocomplete
                                disablePortal
                                id="grouped-item"
                                options={rows}
                                groupBy={(option) => option.category.toUpperCase()}
                                getOptionLabel={(option) => option.title}
                                renderInput={(params) => <TextField {...params} placeholder="Please select" />}
                                renderGroup={(params) => (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                )}
                                onChange={(e, v, r) => {
                                    setIsStaffSlectionDisabled(false)
                                    setSelectedItem(v);
                                    if(selectedCategoryType==CONST_categoryType.SERVICE){
                                        dispatch(
                                            fetchEmployeeServiceRelationData({
                                                companyId: companyId,
                                                serviceId: v?.id,
                                                status: "active",
                                                isEmplPermanent: true,
                                            })
                                        );
                                    }else{
                                        dispatch(
                                            getStaffs(v?.companyId)
                                        );

                                    }
                                    
                                }}
                            />
                        </Grid>

                    </FormControl>
                </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
                <Stack spacing={1}>
                    <InputLabel sx={{ color: 'grey.500', fontWeight: '400' }}>Quantity</InputLabel>
                    <TextField
                        fullWidth
                        type="number"
                        name="quantity"
                        value={selectedQuantity}
                        onChange={handleAddInvoiceItem}
                        error={Boolean(errors.quantityError)}
                        helperText={errors.quantityError}
                        disabled={!selectedItem?.id}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
                <Stack spacing={1}>
                    <InputLabel sx={{ color: 'grey.500', fontWeight: '400' }}>Amount (₹)</InputLabel>
                    <TextField fullWidth name="amount" value={Math.round(amount * 100) / 100} />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                    <Button color="error" onClick={() => setAddItemClicked(false)}>
                        Cancel
                    </Button>
                    <Button disabled={!selectedItem?.id || !selectedQuantity || !isValidForm } variant="contained" size="small" onClick={handleOnAddItem}>
                        Add
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
}

SelectItem.propTypes = {
    handleAddItem: PropTypes.func,
    setAddItemClicked: PropTypes.func
};

export default SelectItem;
