import React from "react";
import PropTypes from "prop-types";

// material-ui
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// material-ui
// third-party

// project imports
import MainCard from "ui-component/cards/MainCard";
import { useAppSelector as useSelector } from "store";
// assets
import dayjs from "dayjs";
import { capitalize, userName } from "utils/general";
import { TableHead, useTheme, alpha } from "@mui/material";
import { v4 as uuid } from "uuid";
import { useCountyPrice } from "components/hooks/useCountyPrice";
import { ThemeMode } from "config";
import BorderRadius from "layout/Customization/BorderRadius";
import { Link } from "react-router-dom";

export const InventoryBilledTable = ({orderDetails }) => {
    
  const theme = useTheme();
  const { events } = useSelector((state) => state.calendar);
//   const { employeeList } = useSelector((state) => state.employees);
  
  const { format } = useCountyPrice();
  let color;
  let chipcolor;
//   const clientAppointment = events?.filter?.(
//     (i) => i?.client === clientDetail?.id
//   );

  return (
    <MainCard content={false} sx={{borderRadius:"0px !important"}}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750, border:"1px solid #e9e9e9"}}
          aria-labelledby="tableTitle"
          className="invoice-main-table"
        >
          <TableHead sx={{background:"#e3f2fd"}}>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Invoice Number</TableCell>
              <TableCell align="right">Invoice Date</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Invoice Link</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {orderDetails?.length > 0 &&
              orderDetails?.map((transaction) => (
                <TableRow key={uuid()} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>{transaction?.itemTitle}</TableCell>
                  <TableCell>{transaction?.invoice?.invoiceNo}</TableCell>
                  <TableCell align="right">{dayjs(transaction?.invoice?.invoiceDate).format('DD-MM-YYYY')}</TableCell>
                  <TableCell align="right">{transaction?.itemQuantity}</TableCell>
                  <TableCell align="right"><Link to={`/invoices/edit-invoice?id=${transaction?.invoiceId}&status=view`}>{transaction?.invoiceId}</Link></TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

InventoryBilledTable.propTypes = {
  productCatalogues: PropTypes.object,
};

export default InventoryBilledTable;
